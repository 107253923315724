const CreateTicketAttendant = {
  data: {},
  pages: [
    {
      elements: [
        {
          isRequired: true,
          name: 'firstName',
          placeholder: 'Enter your first name',
          defaultValue: '',
          label: 'First Name',
          full: true
        },
        {
          isRequired: true,
          name: 'lastName',
          placeholder: 'Enter your last name',
          defaultValue: '',
          label: 'Last Name',
          full: true
        }
      ]
    },
    {
      elements: [
        {
          isRequired: true,
          name: 'phone',
          defaultValue: '',
          placeholder: 'Enter your phone number',
          label: 'Mobile Number',
          type: 'tel',
          full: true
        },
        {
          isRequired: true,
          name: 'email',
          placeholder: 'Enter your E-mail',
          defaultValue: '',
          label: 'Email',
          type: 'email',
          full: true
        }
      ]
    },
    {
      elements: [
        {
          type: 'questions_answer',
          isRequired: true,
          name: 'questions',
          placeholder: 'The event organizer requires more information for the event',
          label: 'Questions',
          full: true
        }
      ]
    }
  ]
};

export default CreateTicketAttendant;
