import { useStateMachine } from 'little-state-machine';
import { useApi } from 'providers/ApiProvider';
import React, { useEffect, useState } from 'react';
import { EventApi } from 'services/api/event-api';
import tw from 'twin.macro';
import { setTime } from 'utils/date';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from '@reach/router';
import { login } from 'utils/auth';
import { navigate } from 'gatsby';
import Button from 'components/Button';
import moment from 'moment';
import { decode } from 'js-base64';
import { CreateEventAction, clearEventForm } from '../store/actions/CreateEventAction';
import Col from './Col';
import Form from './Form';
import Row from './Row';
import Text from './Text';

const Wrapper = tw.div` pt-0`;
const Header = tw.div``;

export default function EventTos({ event, onCancel, onAccept }) {
  const rawDescription = event.tos.includes('b64:')
    ? decode(event.tos.split('b64:')[1])
    : event.tos;

  const clearOnCancel = () => {
    onCancel();
  };
  return (
    <>
      <Wrapper>
        <Header>
          <Col>
            <Row spread>
              <Col>
                <Text label="Terms and Conditions" xlarge bold />
                <Text
                  label="By clicking continue you are agreeing to the following terms and conditions"
                  small
                />
              </Col>
              <Button onClick={clearOnCancel} bold large label="✗">
                ✗
              </Button>
            </Row>
            <Row spacing="sm" />
            <Text dangerouslySetInnerHTML={{ __html: rawDescription }} spacing="sm" body />
            <Button onClick={onAccept} bold large label="Continue" primary>
              Continue
            </Button>
          </Col>
        </Header>
      </Wrapper>
    </>
  );
}
